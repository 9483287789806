import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

// Components
import { CaseStudy, Footer, Layout, SEO } from "../components/common"
import {
  Capabilities,
  Clients,
  Hero,
  Testimonials,
} from "components/landing-page"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(case-studies)/" } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              subtitle
              about
              mockup {
                childImageSharp {
                  fluid(maxWidth: 750, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
              services {
                service
              }
              testimonial {
                client
                text
                clientLogo {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                      ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const [currentCaseStudy, setCurrentCaseStudy] = useState(0)

  const caseStudies = data.allMarkdownRemark.edges

  const { slug } = caseStudies[currentCaseStudy].node.fields
  const { title, subtitle, about, services, mockup, testimonial } = caseStudies[
    currentCaseStudy
  ].node.frontmatter

  const imageData = mockup.childImageSharp.fluid

  const changeCaseStudy = direction => () => {
    const newCurrent =
      direction === "right"
        ? currentCaseStudy === caseStudies.length - 1
          ? 0
          : currentCaseStudy + 1
        : currentCaseStudy === 0
        ? caseStudies.length - 1
        : currentCaseStudy - 1

    setCurrentCaseStudy(newCurrent)
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <Clients />

      <CaseStudy
        about={about}
        caseStudyTitle={title}
        changeCaseStudy={changeCaseStudy}
        imageData={imageData}
        key={title}
        sectionTitle="Case Studies"
        services={services}
        slug={slug}
        subtitle={subtitle}
      />

      <Testimonials key={`testimonial-${title}`} testimonial={testimonial} />
      <Capabilities />
      <Footer heading="Whatever you’re working on, we want to hear about it!!" />
    </Layout>
  )
}

export default IndexPage
